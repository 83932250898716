import { inject, injectable } from 'inversify';
import { GetBookingsFilter, GetStopSignalOrderParams, IBookingService } from './IBookingService';
import { BookingDto, CreateBookingDto, StopSignalOrderDto } from '@/types/webapi';
import { AxiosInstance, IAxiosId } from '@/plugins/axios';
import { format } from 'date-fns';
import { isAxiosError } from 'axios';

@injectable()
export class BookingService implements IBookingService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    getBookings(params?: GetBookingsFilter): Promise<BookingDto[]> {
        return new Promise<BookingDto[]>((resolve, reject) => {
            this.axios
                .get<BookingDto[]>('/api/v1.0/bookings/my', { params })
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    getBooking(id: string): Promise<BookingDto> {
        return new Promise<BookingDto>((resolve, reject) => {
            this.axios
                .get<BookingDto>(`/api/v1.0/bookings/${id}`)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    createBooking(params: CreateBookingDto): Promise<BookingDto> {
        return new Promise<BookingDto>((resolve, reject) => {
            this.axios
                .post<BookingDto>('/api/v1.0/bookings', params)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }

    cancelBooking(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios
                .put(`/api/v1.0/bookings/${id}/cancel`)
                .then(() => resolve())
                .catch(reject);
        });
    }

    getStopSignalOrder(params: GetStopSignalOrderParams): Promise<StopSignalOrderDto | undefined> {
        return new Promise((resolve, reject) => {
            const { activeDate, serviceJourneyRef, stopSequence } = params;

            this.axios
                .get<StopSignalOrderDto>(
                    `/api/v1.0/bookings/stopSignalOrder/${format(activeDate, 'yyyy-MM-dd')}/${serviceJourneyRef}/${stopSequence}`
                )
                .then(response => resolve(response.data))
                .catch(e => {
                    if (isAxiosError(e) && e.response?.status === 404) {
                        return resolve(undefined);
                    }

                    reject(e);
                });
        });
    }
}
