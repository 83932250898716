import { defineStore } from 'pinia';
import { User, Claim } from './User';
import { AxiosInstance, IAxiosId } from '@/plugins/axios';
import { useContainer } from '@/plugins/inversify';
import { MenuPageLink } from '@geta/kolumbus-frontend/composables';
import { computed, reactive, ref, toRefs } from 'vue';
import { UserInfo as ProfileInfo } from '@geta/kolumbus-frontend/models';
import { GetProfileResponseDto, GetUserResponseDto } from '@/types/webapi';
import { IProfileService, IProfileServiceId } from '@/services';
import { useMainStore } from '../common/stores';
import { isAxiosError } from 'axios';
import { CustomError } from '../common/models';

const initialUser: User = { isLoggedIn: false, name: '', isEmailConfirmed: false, isPhoneNumberConfirmed: false };
const initialProfile: ProfileInfo = { displayName: '' };
const initialClaims: Claim[] = [];

const useUserStore = defineStore('userStore', () => {
    const mainStore = useMainStore();
    const container = useContainer();
    const profileService = container.get<IProfileService>(IProfileServiceId);
    const axios = container.get<AxiosInstance>(IAxiosId);

    const state = reactive({
        user: initialUser,
        claims: initialClaims,
        profile: initialProfile
    });

    const isLoading = ref(false);
    const isRequesting = ref(false);
    const isVerifying = ref(false);
    const isLoggedIn = computed(() => state.user.isLoggedIn);
    const isPhoneNumberConfirmed = computed(() => state.user.isPhoneNumberConfirmed);
    const getProfile = computed(() => state.profile);

    function getClaim(name: string) {
        return state.claims.find(x => x.type == name)!.value;
    }

    async function loadUserSilent() {
        const { data, status } = await axios.get<Claim[]>('/bff/user', {
            headers: {
                'X-CSRF': '1'
            },
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status == 401; // allow 401 - silent-login will handle it
            }
        });

        if (status != 200) {
            return;
        }

        state.claims = data;
        state.user = {
            isLoggedIn: true,
            name: getClaim('name'),
            isEmailConfirmed: false,
            isPhoneNumberConfirmed: false
        };

        state.profile.displayName = state.user.name;
    }

    async function loadProfile(profilePage?: Record<string, MenuPageLink>) {
        if (!state.user.isLoggedIn) return;

        const { data, status } = await axios.get<GetProfileResponseDto>('/api/v1.0/profile', {
            headers: {
                'X-CSRF': '1'
            },
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status == 404; // allow 404 - profile not created
            }
        });

        if (status != 200) {
            return;
        }

        state.profile.displayName = `${data.firstName} ${data.lastName}`;

        if (profilePage) {
            state.profile.profilePage = profilePage;
        }
    }

    async function loadUser() {
        if (!state.user.isLoggedIn) return;

        const { data, status } = await axios.get<GetUserResponseDto>('/api/v1.0/profile/user', {
            headers: {
                'X-CSRF': '1'
            },
            validateStatus: function (status) {
                return (status >= 200 && status < 300) || status == 404; // allow 404 - profile not created
            }
        });

        if (status != 200) return;

        state.user.isEmailConfirmed = data.emailConfirmed;
        state.user.isPhoneNumberConfirmed = data.phoneNumberConfirmed;
        state.user.phone = data.phone;
    }

    async function requestForPhoneVerification(phone?: { countryCode: string; number: string }) {
        isRequesting.value = true;

        try {
            // if logged in, use user phone number
            // else use provided phone number
            if (!phone && isLoggedIn.value) {
                phone = state.user.phone;
            }

            // if phone number is missing, throw error
            if (!phone?.countryCode || !phone.number) {
                mainStore.registerError(new CustomError('ProfileError', 'Invalid params'));
                return;
            }

            isLoading.value = true;

            await profileService.sendPhoneConfirmation(phone);
        } catch (e) {
            console.error(e);

            if (isAxiosError(e)) {
                mainStore.registerError(
                    new CustomError('ProfileError', e.response?.data || 'An error occurred while requesting for phone confirmation code')
                );
            }
        } finally {
            isLoading.value = false;
            isRequesting.value = false;
        }
    }

    async function checkPhoneVerificationCode(code: string, phone?: { countryCode: string; number: string }): Promise<boolean> {
        isVerifying.value = true;

        try {
            // if logged in, use user phone number
            // else use provided phone number
            if (!phone && isLoggedIn.value) {
                phone = state.user.phone;
            }

            // if phone number is missing, throw error
            if (!phone?.countryCode || !phone.number || !code) {
                mainStore.registerError(new CustomError('ProfileError', 'Invalid params'));
                return false;
            }

            isLoading.value = true;

            const result = await profileService.verifyPhoneConfirmation(phone, code);

            if (isLoggedIn.value) {
                state.user.isPhoneNumberConfirmed = result;
            }

            return result;
        } catch (e) {
            console.error(e);

            if (isAxiosError(e)) {
                mainStore.registerError(
                    new CustomError('ProfileError', e.response?.data || 'An error occurred while verifying phone confirmation code')
                );
            }

            return false;
        } finally {
            isLoading.value = false;
            isVerifying.value = false;
        }
    }

    return {
        ...toRefs(state),
        isLoading,
        isRequesting,
        isVerifying,
        isLoggedIn,
        getProfile,
        isPhoneNumberConfirmed,
        loadUserSilent,
        loadProfile,
        loadUser,
        requestForPhoneVerification,
        checkPhoneVerificationCode
    };
});

export default useUserStore;
