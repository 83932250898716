<template>
    <l-layer-group>
        <l-circle :lat-lng="latLng" :visible="visible" v-bind="circleProps">
            <l-circle-marker :lat-lng="latLng" :visible="visible" v-bind="circleMarkerProps" />
        </l-circle>
    </l-layer-group>
</template>

<script setup lang="ts">
import { LLayerGroup, LCircle, LCircleMarker } from '@vue-leaflet/vue-leaflet';
import { CircleMarkerOptions, CircleOptions, LatLngExpression } from 'leaflet';
import { computed } from 'vue';
import colors from '@geta/kolumbus-frontend/styles/colors.module.scss';

interface Props {
    modelValue: GeolocationCoordinates;
    visible?: boolean;
}

const props = defineProps<Props>();

const latLng = computed<LatLngExpression>(() => ({ lat: props.modelValue.latitude, lng: props.modelValue.longitude }));
const circleRadius = computed(() => props.modelValue.accuracy || 0);

const circleMarkerProps: CircleMarkerOptions = {
    stroke: true,
    color: colors.white,
    weight: 2,
    fill: true,
    fillColor: colors.blue,
    fillOpacity: 1,
    interactive: false,
    pane: 'markerPane'
};

const circleProps = computed<CircleOptions>(() => ({
    color: colors.blue,
    opacity: 0.1,
    fillColor: colors.blue,
    fillOpacity: 0.1,
    interactive: false,
    radius: circleRadius.value,
    pane: 'markerPane'
}));
</script>
