<template>
    <map-underlay
        ref="underlay"
        v-model:center="center"
        v-model:zoom="zoom"
        v-model:bounds="bounds"
        :grayscale="grayscale"
        :contextmenu="contextmenu"
        :google-mutant-options="googleMutantOptions"
        :user-location="userLocation"
        :can-use-geolocation="canUseGeolocation"
        :padding="padding"
        :padding-top-left="paddingTopLeft"
        :padding-bottom-right="paddingBottomRight"
        @locate-user="locateUser"
    >
        <router-view name="mapUnderlay" />
    </map-underlay>
</template>

<script setup lang="ts">
import { MapUnderlay } from '@/features/map/components';
import { useMapUnderlay } from '@/features/map/composables';
import { MapUnderlayProvideKey } from '@/injectionKeys';
import { provide, ref } from 'vue';

const underlay = ref<typeof MapUnderlay>();
const {
    center,
    zoom,
    bounds,
    padding,
    paddingTopLeft,
    paddingBottomRight,
    googleMutantOptions,
    userLocation,
    canUseGeolocation,
    locateUser,
    grayscale,
    contextmenu
} = useMapUnderlay();

provide(MapUnderlayProvideKey, { flyTo: (latLng, zoom) => underlay.value?.flyTo(latLng, zoom) });
</script>
