<template>
    <v-switch v-model="isDebugMode" hide-details color="primary" :label="`Debug: ${isDebugMode}`" class="switch-on-right"></v-switch>
</template>

<script setup lang="ts">
import { useMainStore } from '@/features/common/stores';
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { i18nRoute } from '@/i18n';

const router = useRouter();
const { isDebugMode } = storeToRefs(useMainStore());

watch(isDebugMode, async () => {
    const route: any = { query: { debugMode: isDebugMode.value } };
    await router.push(i18nRoute(route));
    router.go(0);
});
</script>

<style lang="scss">
.switch-on-right {
    display: flex;
    justify-content: flex-end;
}
</style>
