<template>
    <div ref="$el" class="v-input-wrapper" :class="cssClasses" :style="cssStyles">
        <div v-if="slots.prepend" class="v-input-wrapper__prepend mr-2">
            <slot name="prepend" />
        </div>
        <slot />
        <div v-if="slots.append" class="v-input-wrapper__append ml-2">
            <slot name="append" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';

interface Props {
    height?: string | number;
    focused?: boolean;
}

const $el = ref<HTMLDivElement>();
const props = defineProps<Props>();
const slots = useSlots();
const cssClasses = computed(() => [{ 'v-input-wrapper--focused': props.focused }]);
const cssStyles = computed(() => ({ height: props.height ? `${Number(props.height)}px` : undefined }));

defineExpose({ $el });
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

:root {
    --v-input-wrapper-padding: 0 #{$spacer * 2};
    --v-input-wrapper-border-color: var(--v-color-grey-lighten-2);
    --v-input-wrapper-border: 1px solid;
    --v-input-wrapper-border-radius: #{map-get($rounded, 'lg')};
}

.v-input-wrapper {
    background-color: var(--v-color-white);
    padding: var(--v-input-wrapper-padding);
    border: var(--v-input-wrapper-border);
    border-color: var(--v-input-wrapper-border-color);
    border-radius: var(--v-input-wrapper-border-radius);
    display: flex;
    align-items: start;

    &--focused {
        --v-input-wrapper-border-color: rgb(var(--v-theme-primary));
        @include elevation(2, true);
    }

    &__prepend,
    &__append {
        padding-top: 7px;
    }
}
</style>
