<template>
    <v-navigation-drawer
        v-model="_modelValue"
        :location="isMobile ? 'bottom' : 'left'"
        width="auto"
        color="transparent"
        elevation="0"
        border="0"
        permanent
        class="app-drawer"
        :class="cssClasses"
    >
        <div class="app-drawer__inner">
            <div class="app-drawer__content bg-white" :class="appDrawerContentCssClasses">
                <slot />
            </div>
            <div class="app-drawer__toggle">
                <v-btn
                    color="white"
                    width="30"
                    min-width="30"
                    height="54"
                    rounded="s-0 e-lg"
                    variant="flat"
                    class="pa-0 ma-0"
                    @click="_modelValue = !_modelValue"
                >
                    <v-icon size="36" :icon="_modelValue ? 'mdi-menu-left' : 'mdi-menu-right'" />
                </v-btn>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { useDisplayHelpers } from '@/features/common/composables';
import { computed } from 'vue';

interface Props {
    modelValue: boolean;
    fullHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), { fullHeight: true });
const _modelValue = useVModel(props, 'modelValue');
const { isMobile } = useDisplayHelpers();
const cssClasses = computed(() => ({ 'app-drawer--full-height': props.fullHeight }));
const appDrawerContentCssClasses = computed(() => ({ 'rounded-t-lg': isMobile.value && !props.fullHeight }));
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.app-drawer {
    pointer-events: none;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));

    &.v-navigation-drawer.v-navigation-drawer--left {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
            transform: translateX(calc(-100% + 30px)) !important;

            &.v-navigation-drawer--active {
                transform: translateX(0%) !important;
            }
        }
    }

    &__toggle {
        display: none;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            display: flex;
            align-items: center;
        }

        > * {
            pointer-events: all;
        }
    }

    &__content {
        pointer-events: all;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100vw;
        position: relative;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            width: 614px;
        }
    }

    &__inner,
    &__content,
    .v-navigation-drawer__content {
        display: flex;
        flex: 1 0 auto;
    }

    &--full-height {
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            height: calc(100% - var(--tp-app__header-height)) !important;
        }
    }
}
</style>
