import { useContainer } from '@/plugins/inversify';
import { IConfigService, IConfigServiceId } from '@/services/IConfigService';
import { AppConfigDto } from '@/types/webapi';
import { MenuStructure, useMenuProvider } from '@geta/kolumbus-frontend/composables';
import { isNull, isObject, isUndefined, merge } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, ref, toRefs } from 'vue';

export enum SearchMode {
    None = 0,
    Trip = 1,
    Departures = 2
}

interface MainStore {
    ready: boolean;
    isLoading: boolean;
    isDebugMode: boolean | undefined;
    language: string;
    searchMode: SearchMode;
    config: AppConfigDto & { supportedLanguages: string[] };
    menuItems?: MenuStructure;
    lastKnownError: Error | null;
    showAppDrawer: boolean;
    isExperimentalMode: boolean;
}

const defaultState = (): MainStore => ({
    ready: false,
    isLoading: false,
    language: document.documentElement.lang || 'no',
    isDebugMode: false,
    searchMode: SearchMode.Trip,
    config: {
        kolumbusWebsiteBaseUrl: '',
        supportedLanguages: ['no', 'en'],
        googleMapsConfig: {
            apiKey: '',
            center: {
                latitude: 58.97,
                longitude: 5.7331
            }
        },
        silentLoginEnabled: true,
        defaultCulture: 'no',
        isDebugModeEnabled: false,
        experimentalMode: false
    },
    menuItems: {
        baseUrl: '/'
    },
    lastKnownError: null,
    showAppDrawer: true,
    isExperimentalMode: false
});

export const useMainStore = defineStore('mainStore', () => {
    const container = useContainer();
    const configService = container.get<IConfigService>(IConfigServiceId);

    const state = reactive(defaultState());
    const menu = computed(() => state.menuItems);
    const currentTime = ref<Date>(new Date());

    async function init() {
        try {
            state.isLoading = true;

            // fetch app config
            const config = await configService.getConfig();

            // set up app state
            const supportedLanguages = merge(['no', 'en'], state.menuItems?.supportedLanguages?.map(l => l.code) || []);
            state.config = merge(state.config, config, { supportedLanguages });
            state.language = config.defaultCulture;
            state.isExperimentalMode = config.experimentalMode;

            // set up menus
            const menuProvider = useMenuProvider(config.kolumbusWebsiteBaseUrl);
            state.menuItems = await menuProvider.getMenuItems();
        } catch (e) {
            registerError(e);
        } finally {
            state.isLoading = false;
        }
    }

    function reset(newState: MainStore) {
        Object.assign(state, newState);
    }

    function toggleSearch() {
        state.searchMode = state.searchMode === SearchMode.None ? SearchMode.Trip : SearchMode.None;
    }

    function registerError(error: any) {
        state.lastKnownError = isErrorWithMessage(error) ? error : new Error(String(error));
    }

    const isDebugModeEnabled = computed(() => state.config.isDebugModeEnabled);

    setInterval(() => (currentTime.value = new Date()), 1000);

    return {
        ...toRefs(state),
        currentTime,
        menu,
        init,
        reset,
        toggleSearch,
        registerError,
        isDebugModeEnabled
    };
});

function isErrorWithMessage(error: unknown): error is Error {
    return !isUndefined(error) && !isNull(error) && isObject(error) && 'message' in error && typeof error.message === 'string';
}
