import { useSessionStorage, watchDebounced } from '@vueuse/core';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../stores';
import { storeToRefs } from 'pinia';

const MIN_THRESHOLD = 10;

export function useExperimentalModeActivator() {
    const sessionStorage = useSessionStorage('travelplanner__experimentalMode', { enabled: false });

    const mainStore = useMainStore();
    const { isExperimentalMode } = storeToRefs(mainStore);
    const router = useRouter();
    const { currentRoute } = router;
    const modeChangeCounter = ref(0);

    function init() {
        if (!isExperimentalMode.value) {
            isExperimentalMode.value = sessionStorage.value.enabled;
        }
        watch(currentRoute, (value, oldValue) => {
            if (value.name === oldValue.name) return;

            modeChangeCounter.value += 1;
        });

        watch(modeChangeCounter, value => {
            if (value < MIN_THRESHOLD) return;

            isExperimentalMode.value = true;
            // save state in session storage
            sessionStorage.value.enabled = true;
        });

        watchDebounced(modeChangeCounter, () => (modeChangeCounter.value = 0), { debounce: 10000, maxWait: 15000 });
    }

    return { init };
}
