import { DateType } from '@/features/common/models';
import { useMainStore } from '@/features/common/stores';
import { type DeparturesSearchRequest } from '@/features/departures/models';
import { usePlaceAutocompleteHistory } from '@/features/places/composables';
import { useContainer } from '@/plugins/inversify';
import { ISearchService, ISearchServiceId } from '@/services';
import { type DepartureDto, type QuayDto, type DepartureStopPlaceDto, type PlaceAutocompleteModelDto, PlaceType } from '@/types/webapi';
import { isDefined } from '@vueuse/core';
import { type AxiosError } from 'axios';
import { merge } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs, watch } from 'vue';
import { useGtm } from 'vue-gtm';

export interface State {
    searchRequest: DeparturesSearchRequest;
    selectedDepartureId?: string | null;
    selectedDepartureIdIsExpanded?: boolean;
}

const defaultSearchRequest = Object.freeze<DeparturesSearchRequest>({
    quay: null,
    dateTime: undefined,
    dateTimeType: DateType.Now,
    filters: {
        modes: [],
        quays: []
    }
});

const defaultSearchResult = Object.freeze({
    errors: [] as string[],
    stopPlace: null as DepartureStopPlaceDto | null,
    departureGroups: {} as { [key: string]: DepartureDto[] }
});

export const useDeparturesSearchStore = defineStore('departuresSearchStore', () => {
    const container = useContainer();
    const searchService = container.get<ISearchService>(ISearchServiceId);
    const { addItem } = usePlaceAutocompleteHistory<PlaceAutocompleteModelDto>('pastQuays');
    const { registerError } = useMainStore();
    const gtm = useGtm();

    const state = reactive({
        isDirty: false,
        isSearching: false,
        isLoading: false,
        searchRequest: { ...defaultSearchRequest },
        selectedDepartureId: null as string | null,
        selectedDepartureIdIsExpanded: false
    });
    const searchResult = reactive({ ...defaultSearchResult });
    const canSearch = computed(() => Boolean(state.searchRequest.quay?.id));
    const hasResult = computed(() => Boolean(searchResult.stopPlace));
    const stopPlaceTransportModes = computed(() => [...(searchResult.stopPlace?.availableTransportModes ?? [])]);
    const stopPlaceQuays = computed<QuayDto[]>(
        () =>
            searchResult.stopPlace?.quays
                // remove duplicates, if any
                .filter((_, i, a) => a.findIndex(x => x.id === a[i].id) === i)
                // sort alphanumerically
                .sort((a, b) => a.publicCode?.localeCompare(b.publicCode || '', 'en', { numeric: true }) || 0) || []
    );
    const selectedDeparture = computed<DepartureDto | undefined>(() => {
        if (!searchResult) return undefined;

        for (const key in searchResult.departureGroups) {
            const found = searchResult.departureGroups[key].find(x => x.id === state.selectedDepartureId);
            if (found) {
                return found;
            }
        }

        return undefined;
    });

    const departureGroups = computed(() => searchResult.departureGroups || {});
    const departures = computed(() => Object.values(searchResult.departureGroups).flatMap(x => x));

    function init(initialState?: State) {
        resetResult();

        state.isDirty = false;
        state.searchRequest = merge({}, { ...defaultSearchRequest }, initialState?.searchRequest);
        state.selectedDepartureId = initialState?.selectedDepartureId || null;
        state.selectedDepartureIdIsExpanded = initialState?.selectedDepartureIdIsExpanded || false;
    }

    function resetFilters() {
        state.searchRequest.filters = {
            modes: [],
            quays: []
        };
    }

    function resetResult() {
        Object.assign(searchResult, { ...defaultSearchResult });
    }

    function isValidSearchRequest(
        request: DeparturesSearchRequest
    ): request is { quay: PlaceAutocompleteModelDto } & DeparturesSearchRequest {
        return isDefined(request.quay?.id) && request.quay.type !== PlaceType.Unknown;
    }

    let searchAbortController: AbortController | undefined;

    async function _executeSearch(searchFunc: Function, onError?: Function) {
        try {
            await searchFunc();
        } catch (e) {
            const { name } = e as AxiosError;
            // in case of AbortController being triggered, do nothing
            if (name === 'CanceledError') return;

            registerError(e);
            onError?.();
        }
    }

    async function search() {
        // search for departures for selected quay and time
        if (!isValidSearchRequest(state.searchRequest)) return;

        resetResult();
        state.isDirty = true;
        state.isLoading = true;

        // register history
        addItem(state.searchRequest.quay);

        // abort previous request
        searchAbortController?.abort();
        searchAbortController = new AbortController();

        await _executeSearch(
            async () => {
                state.isSearching = true;
                // search for trips with selected criteria
                const result = await searchService.searchForDepartures(state.searchRequest, {
                    signal: searchAbortController?.signal
                });

                if (result) {
                    searchResult.stopPlace = result.stopPlace;
                    searchResult.departureGroups = result.departureGroups;
                }

                state.isSearching = false;
                state.isLoading = false;

                // track event
                gtm?.trackEvent({
                    event: 'app_event',
                    category: 'departures_search',
                    action: 'search',
                    hasDateTime: !!state.searchRequest.dateTime
                });
            },
            () => {
                state.isSearching = false;
                state.isLoading = false;
            }
        );
    }

    function cancelSearch() {
        searchAbortController?.abort();
    }

    watch(
        () => state.selectedDepartureId,
        (value, oldValue) => {
            if (value === oldValue) return;

            // reset expanded item
            state.selectedDepartureIdIsExpanded = false;
        }
    );

    // TODO: should we just reset the modes filter?
    watch(
        () => searchResult.stopPlace,
        value => {
            if (!value) return;

            const { availableTransportModes } = value;
            // if any selected modes are missing in availableTransportModes, remove missing items
            if (state.searchRequest.filters.modes.every(m => availableTransportModes.includes(m))) return;

            state.searchRequest.filters.modes = state.searchRequest.filters.modes.filter(m => availableTransportModes.includes(m));
        },
        { deep: true }
    );

    watch(hasResult, value => {
        // we only want to check against a valid result
        if (!value || !departures.value.length) {
            state.selectedDepartureId = null;
            state.selectedDepartureIdIsExpanded = false;
            return;
        }

        // check if selectedTripPatternId exists in list
        // if it doesn't, reset selected trip pattern stuff
        if (!departures.value.some(x => x.id === state.selectedDepartureId)) {
            // TODO: show error for invalid trip pattern ID?
            state.selectedDepartureId = null;
            state.selectedDepartureIdIsExpanded = false;
        }
    });

    return {
        ...toRefs(state),
        searchResult,
        canSearch,
        hasResult,
        stopPlaceTransportModes,
        stopPlaceQuays,
        selectedDeparture,
        departureGroups,
        init,
        resetFilters,
        resetResult,
        search,
        cancelSearch
    };
});
